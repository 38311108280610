
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Home extends Vue {
  public schema?: any | null = null;
  public loading = true;
  public failed = false;

  get logoURL() {
    return this.schema?.meta["site-logo"] || "/img/nisto-logo.png";
  }

  async created() {
    this.schema = await (this.$route.query.schema
      ? this.$dataService.getSchema(Number(this.$route.query.schema))
      : this.$dataService.getLatestSchema());
    this.loading = false;

    window.document.title =
      this.schema?.meta["site-title"] || "Nisto People - Survey";
  }

  async startSurvey() {
    // (optional) Wait until recaptcha has been loaded.
    await this.$recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    await this.$recaptcha("survey");
    this.$router.push({
      name: "Survey",
      query: {
        email: this.$route.query.email,
        schema: this.$route.query.schema,
      },
    });
  }
}
