
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class GenerateId extends Vue {
  @Prop() id?: string;
  public email = "";
  public schema?: any | null = null;

  get logoURL() {
    return this.schema?.meta["site-logo"] || "/img/nisto-logo.png";
  }

  get mailHref() {
    return (
      "mailto:" +
      this.email +
      "?subject=Nisto People Generated Id&body=Your Nisto People Id is --" +
      this.id
    );
  }

  async created() {
    this.schema = await (this.$route.query.schema
      ? this.$dataService.getSchema(Number(this.$route.query.schema))
      : this.$dataService.getLatestSchema());
    this.email = (this.$route.query.email as string) ?? "";
    window.document.title =
      this.schema?.meta["site-title"] || "Nisto People - Survey";
  }

  CopyToClipboard(event: MouseEvent) {
    const input = document.getElementById("id-input") as HTMLInputElement;

    if (input) {
      /* Select the text field */
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      input.setSelectionRange(0, 0);
      this.$bvToast.show("clipboard-copied");
    }
  }
}
