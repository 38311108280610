
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class NumberField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;

  @Emit()
  input(event: any) {
    return event.target.value;
  }
}
